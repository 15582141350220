import PullToRefresh from 'pulltorefreshjs';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import $ from 'jquery';

if ('Notification' in window && 'serviceWorker' in navigator && 'indexedDB' in window) {
// Firebase configuration
    const firebaseConfig = {
        apiKey: "AIzaSyDAhJy0ASlodOQhwTIlIMhdkrt_67pkI_E",
        authDomain: "saea-app-a7d9c.firebaseapp.com",
        projectId: "saea-app-a7d9c",
        storageBucket: "saea-app-a7d9c.appspot.com",
        messagingSenderId: "923391206362",
        appId: "1:923391206362:web:19a21ca9ddc8dbabccd6e3",
        measurementId: "G-PP9QZD1D7Z"
    };
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

// Detect PWA mode
    function isRunningAsPWA() {
        return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;
    }

// Detect iOS
    function isIOS() {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }

// Detect if user is signed in
    function isUserSignedIn() {
        console.debug(userData);
        return typeof userData !== 'undefined' && userData.isAuthenticated === "1";
    }

// Register the service worker and get the FCM token
    function getFCMToken() {
        navigator.serviceWorker.register('/firebase-messaging-sw.js').then(function (registration) {
            console.debug('Service Worker registered with scope:', registration.scope);

            // Wait until service worker is ready
            navigator.serviceWorker.ready.then(function (registration) {
                console.debug('Service Worker is ready.');
                getToken(messaging, {
                    vapidKey: 'BFlWffHN4v6WXatkJWnH3IKn7zxAf-xxqEDZDFLK10qAqKkAr2oX0ER_UwPY7EygHkNd_sppleUcMHolFGspSvc',
                    serviceWorkerRegistration: registration
                })
                    .then(token => {
                        if (token) {
                            console.debug('FCM Token created:', token);
                            document.getElementById('fcm-debug').textContent = `FCM Token: ${token}`;
                            addToFirebaseGroup(token);
                        }
                    })
                    .catch(err => {
                        console.error('Error getting token:', err);
                    });
            });
        }).catch(function (err) {
            console.error('Service Worker registration failed:', err);
        });
    }

// Request notification permission (Desktop or non-iOS)
    function requestNotificationPermission() {
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                console.debug("Permission granted");
                getFCMToken(); // Once permission is granted, get FCM token
            } else {
                console.debug('Notification permission denied.');
            }
        });
    }

// Add device to Firebase group
    function addToFirebaseGroup(fcmToken) {
        console.debug("addToFirebaseGroup function executed");
        const deviceGroupName = `${userData.first_name}_${userData.user_id}`;
        console.debug('Device Group Name:', deviceGroupName);

        // Send the AJAX request to store the FCM token and associate it with the user's topic
        fetch(ajaxurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                action: 'store_fcm_group',
                group_name: deviceGroupName,
                fcm_token: fcmToken,
            }),
        })
            .then((response) => {
                // Log the raw HTTP response
                console.debug('Raw Response Headers:', response.headers);
                console.debug('Raw Response Status:', response.status);

                // Parse the JSON response
                return response.json().then((data) => ({data, status: response.status}));
            })
            .then(({data, status}) => {
                if (status === 200 && data.success) {
                    // Log success data
                    console.debug('FCM group created successfully:', data);
                    console.debug('Firebase Response (Raw Data):', data.firebase_response);
                } else {
                    // Log error details
                    console.error('Error storing group:', data.message);
                    console.error('Firebase Response (Raw Data):', data.firebase_response || 'No raw response available.');
                }
            })
            .catch((error) => {
                // Log network or processing errors
                console.error('Error sending FCM group request:', error);
            });
    }


// Handle iOS PWA notification permission (with button click)
    function handleIOSPWANotificationPermission() {
        console.debug("handleIOSPWANotificationPermission function executed");

        const notifyButton = document.getElementById('notify-button');
        if (!notifyButton) {
            console.debug("Creating iOS 'enable notifications' button");
            const newNotifyButton = document.createElement('button');
            newNotifyButton.id = 'notify-button';
            newNotifyButton.textContent = 'Enable Notifications';
            document.body.appendChild(newNotifyButton);

            newNotifyButton.addEventListener('click', () => {
                console.debug("Enable Notifications iOS button clicked");
                getFCMToken(); // On click, get the FCM token directly without requesting permission again
                newNotifyButton.remove(); // Remove button after request
            });
        }
    }

// Document ready event
    document.addEventListener('DOMContentLoaded', function () {
        if (isUserSignedIn()) {
            console.debug('User is signed in, checking notification permission.');

            if (Notification.permission === 'granted') {
                console.debug('Notification permission already granted.');
                getFCMToken(); // Get FCM token if permission is already granted
            } else {
                if (isIOS() && isRunningAsPWA()) {
                    handleIOSPWANotificationPermission(); // Show button for iOS PWA
                } else {
                    requestNotificationPermission(); // Auto-prompt for desktop/non-iOS
                }
            }
        } else {
            console.debug('User is not signed in, skipping notification registration.');
        }
    });

// Pull-to-refresh functionality
    PullToRefresh.init({
        mainElement: 'body',
        onRefresh() {
            window.location.reload(); // Refreshes on pull-down
        }
    });


    onMessage(messaging, (payload) => {
        console.debug('Foreground notification received:', payload);

        // Create the popup notification
        const notificationElement = document.createElement('div');
        notificationElement.classList.add('notification-popup');
        notificationElement.innerHTML = `
        <img src="${payload.notification.image || '/default-icon.png'}" alt="Notification Icon">
        <div class="notification-content">
            <div class="notification-title">${payload.notification.title}</div>
            <div class="notification-body">${payload.notification.body}</div>
        </div>
        <button class="close-btn">X</button>
    `;

        // Append to body
        document.body.appendChild(notificationElement);

        // Show the popup
        setTimeout(() => {
            notificationElement.classList.add('show');
        }, 100);

        // Fade away after 15 seconds, unless hovered or interacted with
        let hideTimeout = setTimeout(() => {
            notificationElement.classList.remove('show');
            setTimeout(() => notificationElement.remove(), 500);
        }, 15000);

        // Stop fading when the user hovers
        notificationElement.addEventListener('mouseover', () => {
            clearTimeout(hideTimeout);
        });

        // Resume fading when the user stops hovering
        notificationElement.addEventListener('mouseleave', () => {
            hideTimeout = setTimeout(() => {
                notificationElement.classList.remove('show');
                setTimeout(() => notificationElement.remove(), 500);
            }, 5000);
        });

        // Close notification on "X" click
        notificationElement.querySelector('.close-btn').addEventListener('click', () => {
            notificationElement.classList.remove('show');
            setTimeout(() => notificationElement.remove(), 500);
        });
    });
}